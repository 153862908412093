import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"

const ShippingPolicy = () => {
  return (
    <>
      <Layout>
        <Seo title="Shipping Policy" />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-semibold">
                  Softmatter Shipping Policy
                </div>
              </div>
              <div className=" my-7">
                <div className="mb-4">
                  Shipments are dispatched within 1-7 working days of Order
                  placement and would take approximately 7-14 days to reach you.
                </div>
                <div className="mb-4">
                  For estimates of shipping times, refer to the product page, or
                  check your order confirmation email. You may also check the
                  status of your order by reaching out to
                  softmatter@masholdings.com
                </div>
                <div className="mb-4">
                  Please note that shipping times are approximated from the time
                  your purchase leaves our warehouse.
                </div>
                <div className="mb-4">
                  Most items are available to ship in one to two business days
                  from order date unless otherwise noted. We process, ship, and
                  deliver packages five days per week from Monday to Friday
                  (location and carrier restrictions apply).
                </div>
                <div className="mb-4">P.O. Boxes & APO/FPO Addresses</div>
                <div className="mb-4">
                  Currently, we are unable to offer shipping to AP, FPO
                  addresses as the items are being dispatched from Sri Lanka. We
                  will update our shipping policy when we are able to ship to
                  these locations.
                </div>
                <div className="mb-4 font-firs-medium">Shipping Time Frame</div>
                <ul className="pl-10 list-disc ">
                  <li className="mb-4">
                    We try to group all of your orders together so that you
                    receive a single package, though sometimes your orders may
                    arrive in separate packages.
                  </li>
                </ul>
                <div className="mb-4 font-firs-medium">
                  Shipping Restrictions
                </div>
                <ul className="pl-10 list-disc ">
                  <li className="mb-4">
                    Due to Department of Transportation guidelines, regulated
                    materials can only be shipped via standard shipping.
                  </li>
                </ul>
                <div className="mb-4 font-firs-medium">
                  Handling fees, Duties and Taxes
                </div>
                <div className="mb-4">
                  You will be charged handling fees and duties/taxes as your
                  order passes through local customs. Any such charges on an
                  order must be paid by the person receiving the item.
                </div>
                <div className="mb-4 font-firs-medium">
                  Softmatter has no control over these charges and we are unable
                  to advice you on what the cost would be; as customs policies
                  and import duties vary widely from country to country.
                </div>
                <div className="mb-4 font-firs-medium">
                  It might be a good idea to contact your local customs office
                  for current charges before you order.
                </div>
                <div className="mb-4">Disclaimer</div>
                <ul className="pl-10 list-disc ">
                  <li className="mb-4">
                    While we’ll do all we can to ensure your order is delivered
                    on time, Company is not responsible for shipping delays
                    caused by circumstances outside of our control.
                  </li>
                  <li className="mb-4">
                    We will keep you updated on your order status via email. You
                    can also check the status of your order anytime by writing
                    to softmatter@masholdings.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ShippingPolicy
